export default [
  {
    title: 'Pools',
    icon: 'ClipboardIcon',
    route: 'browse-cohorts',
    // acl
    action: 'view',
    resource: 'Cohort',
  },
]
