export default [
  {
    title: 'Promotion',
    icon: 'StarIcon',
    route: 'create-promotion',
    // acl
    action: 'create',
    resource: 'Promotion',
  },
]
