export default [
  {
    title: 'Questions',
    icon: 'AwardIcon',
    children: [
      {
        title: 'All Questions',
        route: 'browse-questions',
        // acl
        action: 'read',
        resource: 'Question',
      },
      {
        title: 'Create Question',
        route: 'create-question',
        // acl
        action: 'create',
        resource: 'Question',
      },
    ],
  },
]
