export default [
  {
    title: 'Registration',
    icon: 'UserPlusIcon',
    route: 'registration-report',
    // acl
    action: 'view',
    resource: 'Registration',
  },
]
