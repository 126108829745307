export default [
  {
    title: 'Winners',
    icon: 'AwardIcon',
    route: 'browse-winners',
    // acl
    action: 'view',
    resource: 'Winner',
  },
]
